import { Button, Spinner } from '@capturi/ui-components'
import {
  OptionProps,
  PopoutSelect,
  SelectOption,
  components,
} from '@capturi/ui-select'
import {
  Box,
  Icon,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import { t } from '@lingui/macro'
import React from 'react'
import { MdArrowDropDown, MdVisibilityOff } from 'react-icons/md'

import { useDashboards } from '../../hooks'
import { BaseDashboard } from '../../types'

type DashboardSelectOption = SelectOption & {
  dashboard?: BaseDashboard
}

type DashboardSelectProps = {
  dashboard?: BaseDashboard
  onSelectDashboard: (dashboard: BaseDashboard) => void
  isSelectionDisabled?: boolean
  initialFocusRef?: React.Ref<HTMLInputElement>
  children?: React.ReactNode
}

const Option: React.ComponentType<
  OptionProps<DashboardSelectOption, boolean>
> = (props) => {
  const { label, dashboard } = props.data as DashboardSelectOption
  return (
    <components.Option {...props}>
      <Box pos="relative" pr={6}>
        <Box noOfLines={1} wordBreak="break-all">
          {label}
        </Box>
        {dashboard?.description != null && (
          <Text
            fontSize="xs"
            color="textMuted"
            noOfLines={1}
            wordBreak="break-all"
          >
            {dashboard.description}
          </Text>
        )}
        <Box pos="absolute" top="2px" right={0}>
          {dashboard?.permissionPreset === 'Private' && (
            <Tooltip label={t`Private: only you can view and edit`}>
              <span>
                <Icon
                  as={MdVisibilityOff}
                  color="textMuted"
                  sx={{
                    path: {
                      stroke: 'currentColor',
                    },
                  }}
                />
              </span>
            </Tooltip>
          )}
        </Box>
      </Box>
    </components.Option>
  )
}

const DashboardSelectPopoverContent: React.FC<DashboardSelectProps> = ({
  dashboard,
  onSelectDashboard,
}) => {
  const { data: dashboards } = useDashboards()

  const options = dashboards?.map<DashboardSelectOption>((d) => ({
    value: d.uid,
    label: d.title,
    dashboard: d,
  }))

  const valueOption = dashboard
    ? {
        value: dashboard?.uid,
        label: dashboard?.title,
        dashboard: dashboard,
      }
    : undefined

  return (
    <PopoutSelect
      placeholder={t`Select a dashboard ...`}
      options={options}
      autoFocus={true}
      value={valueOption}
      noOptionsMessage={() => t`No options`}
      onChange={(value) => {
        const dashboard = (value as DashboardSelectOption)?.dashboard
        if (dashboard == null) return
        onSelectDashboard(dashboard)
      }}
      components={{
        Option,
      }}
    />
  )
}

export const DashboardSelectPopover: React.FC<DashboardSelectProps> = ({
  dashboard,
  onSelectDashboard,
  isSelectionDisabled = false,
  children,
}) => {
  if (isSelectionDisabled) {
    return children
  }

  return (
    <Popover isLazy placement="bottom-start">
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            <Button
              justifyContent="flex-start"
              w="100%"
              variant="ghost"
              px="8px"
              ml="-8px"
              height="fit-content"
              rightIcon={<Icon as={MdArrowDropDown} boxSize="20px" />}
            >
              {children}
            </Button>
          </PopoverTrigger>
          <PopoverContent>
            <React.Suspense
              fallback={<Spinner display="block" m="1rem auto" />}
            >
              <DashboardSelectPopoverContent
                dashboard={dashboard}
                onSelectDashboard={(dashboard) => {
                  onSelectDashboard(dashboard)
                  onClose()
                }}
              />
            </React.Suspense>
          </PopoverContent>
        </>
      )}
    </Popover>
  )
}
